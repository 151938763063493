<template>
  <div class="user-public">
    <div class="user-info-bg"></div>
    <div class="user-info-card" v-if="userData">
      <div class="text-center">
        <img :src="userData.picUrl" class="profile-pic" />
      </div>
      <p class="profile-name">{{userData.displayName}}</p>
      <p class="profile-id" v-if="userData.publicId.length > 0">@{{userData.publicId}}</p>

      <div class="row">
        <div class="col-6">
          <p class="follow-text" @click="showUserFollowersList">{{userData.follower}} 粉絲</p>
        </div>

        <div class="col-6">
          <p class="follow-text" @click="showUserFollowsList">{{userData.following}} 追蹤中</p>
        </div>
      </div>

      <button class="follow-btn" :class="{'green': !isFollowing}" v-if="!!userData && user.serial !== userData.serial" @click="toggleFollowing">
        {{followBtnText}}
      </button>
    </div>

    <div class="user-content">
      <div class="">

        <div class="content-header">
          <div class="row no-gutters">
            <div class="col-6">
              <button class="header-btn" :class="{'active': isContentTypeCloth}" @click="setContentType('cloth')">
                <div class="">
                  <img :src="require('@/assets/nav_icons/share.png')" />
                </div>
                共享衣櫥
              </button>
            </div>

            <div class="col-6">
              <button class="header-btn" :class="{'active': isContentTypeOutfit}" @click="setContentType('outfit')">
                <div class="">
                  <img :src="require('@/assets/nav_icons/home.png')" />
                </div>
                穿搭分享
              </button>
            </div>
          </div>
        </div>

        <div class="content-body">

          <div class="" v-if="isContentTypeCloth">
            <p class="text-center py-3" v-if="cloths.length === 0">
              沒有公開的衣服
            </p>

            <div class="row no-gutters" v-if="cloths.length > 0">
              <div class="col-4 position-relative p-1" v-for="(cloth) in cloths" :key="cloth.serial">
                <PublicClothBlock :cloth="cloth"></PublicClothBlock>
              </div>
            </div>
          </div>

          <div class="" v-if="isContentTypeOutfit">
            <p class="text-center py-3" v-if="outfits.length === 0">
              沒有公開的穿搭
            </p>

            <div class="row no-gutters" v-if="outfits.length > 0">
              <div class="col-4 position-relative p-1" v-for="(outfit) in outfits" :key="outfit.serial">
                <PublicOutfitBlock :outfit="outfit"></PublicOutfitBlock>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <UserFollowListModal ref="followListModal" :data-type="userFollowShowType" :serial="targetSerial" @close="followModalClosed"></UserFollowListModal>

    <transition name="fade">
      <SetPublicIdModal v-if="isSetPublicIdModalShow" @close="closeSetPublicIdModal" @updated="publicIdUpdated">
      </SetPublicIdModal>
    </transition>
  </div>
</template>


<script>
// @ is an alias to /src
import PublicOutfitBlock from '@/components/share/PublicOutfitBlock.vue';
import PublicClothBlock from '@/components/share/PublicClothBlock.vue';
import { mapActions, mapState } from 'vuex';

import UserFollowListModal from '@/components/share/UserFollowListModal.vue';
import SetPublicIdModal from '@/views/share/SetPublicIdModal.vue';

export default {
  name: 'UserPublic',
  data() {
    return {
      readingBusyName: 'USERPUBLICREADING',
      setFollowingBusyName: 'SETFOLLOWING',

      isSetPublicIdModalShow: false,

      userData: null,
      followingSerials: [],
      contentType: 'cloth',

      userFollowShowType: 'follows',
    };
  },
  components: {
    PublicOutfitBlock,
    PublicClothBlock,
    UserFollowListModal,
    SetPublicIdModal,
  },
  computed: {
    ...mapState(['user']),
    cloths() {
      return this.userData === null? []: this.userData.cloths;
    },
    outfits() {
      return this.userData === null? []: this.userData.outfits;
    },
    targetSerial() {
      return parseInt(this.$route.params.serial);
    },
    isFollowing() {
      return this.userData === null || this.followingSerials.indexOf(this.userData.serial) !== -1;
    },
    followBtnText() {
      return this.isFollowing?'追蹤中':'追蹤'
    },
    isContentTypeCloth() {
      return this.contentType === 'cloth';
    },
    isContentTypeOutfit() {
      return this.contentType === 'outfit';
    },
	},
  watch: {
    $route (){
      this.__refresh();
    }
  },
  async mounted() {
    await this.__refresh();
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    setContentType(type) {
      this.contentType = type;
    },
    async toggleFollowing() {
      const follow = !this.isFollowing;
      const serial = this.userData.serial;
      this.appendComponentBusy(this.setFollowingBusyName);
      try {
        const res = await this.$store.dispatch('api/postSetFollowPromise', {
          follow,
          followedSerial: serial,
        });
        if (res.follow) {
          this.followingSerials.push(serial);
        } else {
          this.followingSerials.splice(this.followingSerials.indexOf(serial), 1);
        }
      } catch(e) {
        this.showMsgModal(e);
      } finally {
        this.clearComponentBusy(this.setFollowingBusyName);
      }
    },
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const userData = await this.$store.dispatch('api/getUserPublicDataPromise', this.targetSerial);
        this.userData = Object.assign({}, this.userData, userData);

        this.followingSerials.splice(0, this.followingSerials.length);
        const followings = await this.$store.dispatch('api/getFollowingSerialList');
        for (const f of followings.list) {
          this.followingSerials.push(f);
        }

        if (this.userData.serial === this.$store.state.user.serial) {
          if (this.userData.publicId.length === 0) {
            this.isSetPublicIdModalShow = true;
          }
        }
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    showUserFollowsList() {
      this.userFollowShowType = 'follows';
      this.$refs.followListModal.open();
    },
    showUserFollowersList() {
      this.userFollowShowType = 'followers';
      this.$refs.followListModal.open();
    },
    followModalClosed() {
      this.__refresh();
    },
    closeSetPublicIdModal() {
      this.isSetPublicIdModalShow = false;
    },
    publicIdUpdated(publicId) {
      this.$set(this.userData, 'publicId', publicId);
      this.isSetPublicIdModalShow = false;
    }
  }
}
</script>

<style scoped>
  .user-public {
    position: relative;
  }

  .user-info-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    background-color: var(--main-green);
  }

  .user-info-card {
    position: relative;
    background-color: #fff;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    border-radius: .5rem;
    padding: 1.3rem 1.8rem .8rem;
    box-shadow: 0 4px 8px 0 #0003;
  }

  .profile-pic {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-dark);
    text-align: center;
    margin-top: .5rem;
    margin-bottom: .2rem;
  }

  .profile-id {
    color: #8D8D8D;
    text-align: center;
    margin-bottom: .5rem;
  }

  .follow-text {
    text-align: center;
    color: var(--text-dark);
  }

  .follow-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    border: none;
    border-radius: 999px;
    box-shadow: 0 4px 8px 0 #0003;
    padding: .8em;
    font-size: 12px;
    width: 100px;
    text-align: center;
    background-color: #C4C4C4;
    color: var(--text-dark);
  }

  .follow-btn.green {
    background-color: var(--main-green);
    color: #fff;
  }

  .user-content {

  }

  .content-header {

  }

  .header-btn {
    position: relative;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    opacity: .4;
    margin: 0;
    padding-bottom: 6px;
    background-color: transparent;
    width: 100%;
    color: #000;
  }

  .header-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 2px;
    background-color: #666;
  }

  .header-btn>div {
    padding-right: 4px;
  }

  .header-btn>div>img {
    display: block;
    width: 28px;
    height: 17px;
    object-fit: contain;
  }

  .header-btn.active {
    opacity: 1;
  }

  .content-body {
    padding: .5rem 0;
  }
</style>
