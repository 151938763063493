<template>
  <div class="public-outfit-block">
    <div class="" @click="openInfoModal">
      <OutfitBlock :outfit="outfit"></OutfitBlock>
    </div>

    <transition name="fade">
      <ViewOutfit v-if="isInfoModalShow" :id="outfit.serial" @back="closeInfoModal"></ViewOutfit>
    </transition>
  </div>
</template>


<script>
// @ is an alias to /src
import ViewOutfit from '@/views/closet/ViewOutfit.vue';
import OutfitBlock from '@/components/outfit/OutfitBlock.vue';
import { mapActions } from 'vuex';

export default {
  name: 'PublicOutfitBlock',
  data() {
    return {
      isInfoModalShow: false,
    };
  },
  props: {
    outfit: {
      type: Object,
      required: true,
    },
  },
  components: {
    ViewOutfit,
    OutfitBlock,
  },
  computed: {
	},
  watch: {
  },
  mounted() {
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    openInfoModal() {
      this.isInfoModalShow = true;
    },
    closeInfoModal() {
      this.isInfoModalShow = false;
    },
  }
}
</script>

<style scoped>
  .public-outfit-block {
    position: relative;
  }

</style>
