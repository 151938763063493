<template>
  <div class="public-cloth-block">
    <div class="" @click="openClothInfoModal">
      <ClothBlock :cloth="cloth"></ClothBlock>
    </div>

    <transition name="fade">
      <!-- <ClothSimpleInfoModal v-if="isClothInfoModalShow" :cloth="cloth" @close="closeClothInfoModal">
      </ClothSimpleInfoModal> -->
      <FullScreenModal title="檢視公開單品" v-if="isClothInfoModalShow" @back="closeClothInfoModal">
        <ViewClothContent :cloth="cloth"></ViewClothContent>
      </FullScreenModal>
    </transition>
  </div>
</template>


<script>
// @ is an alias to /src
import ClothBlock from '@/components/ClothBlock.vue';
// import ClothSimpleInfoModal from '@/components/outfit/ClothSimpleInfoModal.vue';
import { mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ViewClothContent from '@/views/closet/ViewClothContent.vue';

export default {
  name: 'PublicClothBlock',
  data() {
    return {
      isClothInfoModalShow: false,
    };
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClothBlock,
    // ClothSimpleInfoModal,
    FullScreenModal,
    ViewClothContent,
  },
  computed: {
	},
  watch: {
  },
  mounted() {
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    openClothInfoModal() {
      this.isClothInfoModalShow = true;
    },
    closeClothInfoModal() {
      this.isClothInfoModalShow = false;
    },
  }
}
</script>

<style scoped>
  .public-cloth-block {
    position: relative;
  }

</style>
