<template>
  <transition name="fade">
    <FullScreenModal v-if="show" :title="title" @back="close">
      <div class="user-follow-list">
        <div class="container">
          <ul class="user-list">
            <li class="user-li" v-for="(user) in list" :key="user.userSerial">
              <div class="user-img" @click="goToUser(user.userSerial)">
                <img :src="user.picUrl" v-if="user.picUrl.length > 0" />
              </div>

              <div class="user-name" @click="goToUser(user.userSerial)">
                <p>{{user.name}}</p>
                <span>@{{user.publicId}}</span>
              </div>

              <div class="user-action" v-if="user.userSerial !== loginUserSerial">
                <button type="button" class="btn btn-follow" :class="{'followed': followingSerials.indexOf(user.userSerial) !== -1}" @click="toggleFollowing(user.userSerial)">{{followingSerials.indexOf(user.userSerial) !== -1? '追蹤中': '追蹤'}}</button>
              </div>
            </li>
          </ul>

        </div>
      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'UserFollowListModal',
  data() {
    return {
      show: false,

      readingBusyName: 'READINGUSERFOLLOWLIST',
      savingBusyName: 'UPDATINGUSERFOLLOWLIST',

      list: [],

      followingSerials: [],
    };
  },
  components: {
    FullScreenModal,
  },
  props: {
    serial: {
      type: Number,
      required: true,
    },
    dataType: {
      validator(value) {
        // The value must match one of these strings
        return ['follows', 'followers'].includes(value)
      },
      default: 'follows',
    },
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.savingBusyName);
  },
  async mounted() {

  },
  computed: {
    ...mapGetters(['loginUserSerial']),
    isTypeFollows() {
      return this.dataType === 'follows';
    },
    title() {
      return `${this.isTypeFollows? '追蹤中': '粉絲'}(${this.list.length})`;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.__refresh();
      }
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        let list = [];
        if (this.isTypeFollows) {
          list = await this.$store.dispatch('api/getFollowsList', this.serial);
        } else {
          list = await this.$store.dispatch('api/getFollowersList', this.serial);
        }
        this.list.splice(0, this.list.length);
        for (const u of list.list) {
          this.list.push(u);
        }

        this.followingSerials.splice(0, this.followingSerials.length);
        const followings = await this.$store.dispatch('api/getFollowingSerialList');
        for (const f of followings.list) {
          this.followingSerials.push(f);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.$emit('close');
    },
    async toggleFollowing(userSerial) {
      const isFollowing = (this.followingSerials.indexOf(userSerial) !== -1);

      this.appendComponentBusy(this.savingBusyName);
      try {
        const res = await this.$store.dispatch('api/postSetFollowPromise', {
          follow: !isFollowing,
          followedSerial: userSerial,
        });
        if (!res.follow) {
          this.followingSerials.splice(this.followingSerials.indexOf(userSerial), 1);
        } else {
          this.followingSerials.push(userSerial);
        }
      } catch(e) {
        this.showMsgModal(e);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    goToUser(userSerial) {
      this.$router.push({ name: 'UserPublic', params: {serial: userSerial}});
      this.show = false;
    },
  }
}
</script>

<style scoped>
  .user-follow-list {

  }

  .user-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .user-li {
    display: flex;
    align-items: center;
    padding: .5rem 0;
  }

  .user-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #c4c4c4;
    flex: 0 0 auto;
    margin-right: .8rem;
  }

  .user-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-name {
    flex: 1 1;
  }

  .user-name>p {
    color: var(--text-dark);
    margin-bottom: 0;
    font-weight: 800;
  }

  .user-name>span {
    color: #c4c4c4;
    font-size: 12px;
    display: block;
  }

  .user-action {
    flex: 0 0 auto;
  }

  .btn-follow {
    font-size: 12px;
    border: none;
    background-color: var(--main-green);
    color: #fff;
    padding: .1em 1em;
  }

  .btn-follow.followed {
    border: solid 1px #c4c4c4;
    background-color: #fff;
    color: var(--text-dark);
  }

</style>
